import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function DeepFriedFunkPage() {
  return (
    <TicketshopPage
      scriptString={`<script data-name-filter="deep" src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-fixr-shop-id="f8e48caf-038f-4062-b6b5-e963e15a7183" data-fixr-theme="light"></script>`}
      imageSrc="/logo-dff.png"
      backgroundSrc="/dff-hero.jpg"
      imageMaxWidth="562px"
      title="DEEP FRIED FUNK"
      description={`Specialising in Funk of the deepest fried variety, Deep Fried Funk provides a colourful backdrop of moving visuals, a welcoming dancefloor filled with funky movers and a friendly atmosphere complimented by a sea of smiles. The brand inducts some of the finest funky selectors to orchestrate your musical journey through sultry sounds & funky flavours on handpick dates throughout the year.`}
    />
  );
}
